





























































import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ComponentCarousel extends Vue {
  @Prop(Array) readonly items!: VNode[];

  currentIndex = 0;
  loading = false;
  transition = "";

  next(): void {
    this.transition = "slide-fade-right";
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }
  previous(): void {
    this.transition = "slide-fade-left";
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.items.length - 1;
    }
  }

  get currentComponent(): VNode {
    return this.items[this.currentIndex];
  }
}
