




















import ComponentCarousel from "../base/ComponentCarousel.vue";
import NinetyNineLogo from "../base/NinetyNineLogo.vue";
export default {
  components: {
    ComponentCarousel,
    NinetyNineLogo,
  },
  computed: {
    carouselComponents() {
      return [
        () => import("@/components/register/SignUpCarouselFirst.vue"),
        () => import("@/components/register/SignUpCarouselSecond.vue"),
        () => import("@/components/register/SignUpCarouselThird.vue"),
        () => import("@/components/register/SignUpCarouselFourth.vue"),
        () => import("@/components/register/SignUpCarouselFifth.vue"),
        () => import("@/components/register/SignUpCarouselSixth.vue"),
      ];
    },
  },
};
